import classNames from 'classnames';
import classes from './alternativeSpanish.module.scss'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import ExplainerWrapper from '../../components/explainerWrapper';


function FourPlayersIntroduction( ) {
  const handleClick = () => { window.location.href = '/'}
    return (
      <ExplainerWrapper title={"PlayCloud - Alternativa gratis a AirConsole"}>
              <div className={classes.wrapper}>
              <header>
        <h1>PlayCloud: Una Alternativa Gratuita a AirConsole</h1>
        <p>¿Buscas una alternativa a AirConsole sin pagar? PlayCloud te ofrece una experiencia de juego multijugador en línea sin costos.</p>
    </header>
    <section>
        <h2>Juega Gratis Sin Descargas Ni Suscripciones</h2>
        <p>Con PlayCloud, no necesitas pagar ni descargar aplicaciones. Accede desde cualquier dispositivo y comienza a jugar con amigos en segundos.</p>
    </section>
    <section>
        <h2>¿Por Qué Elegir PlayCloud?</h2>
        <ul>
            <li>Acceso 100% gratuito, sin costos ocultos ni suscripciones.</li>
            <li>Juega desde el navegador sin necesidad de descargas.</li>
            <li>Compatible con cualquier dispositivo, incluyendo teléfonos, tabletas y PC.</li>
            <li>Variedad de juegos multijugador ideales para fiestas y reuniones.</li>
            <li>Sin anuncios molestos que interrumpan la diversión.</li>
        </ul>
    </section>
    <section>
        <h2>Cómo Funciona PlayCloud</h2>
        <p>PlayCloud es simple y rápido de usar. Solo sigue estos pasos:</p>
        <ol>
            <li>Abre PlayCloud en tu navegador.</li>
            <li>Elige un juego y compártelo con tus amigos.</li>
            <li>Usa tu teléfono como control y comienza a jugar.</li>
        </ol>
    </section>
    <section>
        <h2>Lo Que Dicen Nuestros Jugadores</h2>
        <blockquote>
            <p>"PlayCloud es increíble. Es como AirConsole, pero gratis. ¡Totalmente recomendado!"</p>
            <p>- Marcos, jugador frecuente</p>
        </blockquote>
        <blockquote>
            <p>"Por fin encontré una alternativa gratuita a AirConsole. PlayCloud es fácil de usar y tiene juegos divertidos."</p>
            <p>- Laura, fan de los juegos en línea</p>
        </blockquote>
    </section>
    <section>
        <h2>Empieza a Jugar Ahora</h2>
        <p>Únete a miles de jugadores que ya disfrutan de PlayCloud. Si buscas una alternativa gratuita a AirConsole, prueba PlayCloud y vive la mejor experiencia de juego sin costos.</p>
    </section>   </div>
      </ExplainerWrapper>


  );
}

export default FourPlayersIntroduction;
