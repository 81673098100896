import React, { useEffect, useState, useContext, createContext, useRef, ReactNode } from 'react';

import logo from './logo.svg';
import { io } from 'socket.io-client';
import LaunchContainer from './containers/launch';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import socketService, { getServerData, isSocketRoute, serverAddress } from './services/socket.service'
import GamesContainer from './containers/games';
import GameContainer from './containers/game';
import BrowserControllerContainer from './containers/browserController';
import PlayerSimulatorContainer from './containers/playerSimulator';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import classes from './App.module.scss'
import { isMobile } from 'react-device-detect';
import AdminContainer from './containers/admin';
import { useQuery } from './hooks/useQuery';
import AdminLogsContainer from './containers/adminLogs';
import IntroductionContainer from './containers/introduction';
import Introduction2Container from './containers/introduction2';
import Introduction3Container from './containers/introduction3';
import Introduction4Container from './containers/introduction4';
import Introduction5Container from './containers/introduction5';
import Introduction6Container from './containers/introduction6';
import Introduction7Container from './containers/introduction7';
import Introduction8Container from './containers/introduction8';
import Logo from './components/logo/logo';
import axios from 'axios';
import { stringify } from 'querystring';
import { postAnalytics } from './httpRequests/consoleRequests';
import GameLogsContainer from './containers/gameLogs';
import SpecificGameContainer from './containers/specificGame';
import IntroductionJackboxContainer from './containers/introductionjackbox';
import IntroductionJackbox2Container from './containers/introductionjackbox2';
import CookieConsent from 'react-cookie-consent';
import { initMixpanel } from './services/analytics.service';
import { getTranslation } from './utils/anyUtils';
import DownloadControllerContainer from './containers/downloadController';
import LocalMultiplayerIntroduction from './containers/localmultiplayerintroduction';
import FallGuysAlternative from './containers/fallguysalternative';
import StumbleGuysAlternative from './containers/stumbleguysalternative';
import { articles } from './consts/articles';
import { drawAndGuessGamePath, drawAndGuessGameSpanishPath, drawingGameForFamiliesPath, drawingGameForPartiesPath, drawingGameForTvWithPhonesPath, garticPhoneAlternativePath, iceHockeyPath, kartGamePath, partyGameSpasnishPath, partyGamesPath, partyGamesSimilarPath, partyGamesSimilarPath2, scribleIoAlternativePath } from './consts/gameArticles';
import Credits from './containers/credits';

const WithCookieConsent = ({children}: {children: ReactNode}) => {
  const t = getTranslation("consent")

  return (<>
      <CookieConsent
            onAccept={() => { initMixpanel() }}
            location="bottom"
            buttonText={t.accept}
            buttonWrapperClasses={classes.flex}
            enableDeclineButton
            buttonClasses={classes.btn}
            cookieName="cookieConsent"
            declineButtonStyle={{ fontFamily: 'OpenSans', outline: 'none', color: '#42c3d3', background: 'transparent', padding: '8px 25px', fontSize: "13px" }}
            declineButtonText={t.reject}
            style={{ display: 'flex', background: "#2B373B" }}
            buttonStyle={{ fontFamily: 'OpenSans', background: '#42c3d3', padding: '8px 25px', color: "black", fontSize: "13px" }}
            expires={365}
          >
            {t.message}
          </CookieConsent>
          {children}
  </>)
}
export interface IPlayer {
  from?: string
  color: { r: number, g: number, b: number, a: number },
  name: string,
  id: string
}
export type FeatureToggles = {
  useCdn?: boolean,
  applixirZoneId?: boolean,
  displayFullPageAdsWeb?: boolean,
  displayAdmobBannerControllerNoneAdmin?: boolean,
  showAdsOnMobile?: boolean,
  recommandOtherPlatforms?: boolean,
  isAbleToPlayBrowserAndroid?: boolean,
  isAbleToPlayBrowserIos?: boolean
  isAndroidMobileAppAvailble?: boolean
  isIosMobileAppAvailable?: boolean
}
export const LobbyContext = createContext<{ roomNumber: string | null, admin?: string, gameData?: { orientation: 'portrait' | 'landscape' }, players: IPlayer[] }>({
  roomNumber: null,
  players: [],
});
export const FeatureToggleContext = createContext<FeatureToggles>({})
export const SocketContext = createContext<{ isConnected: boolean }>({
  isConnected: false
});
//@ts-ignore
let deferredPrompt = null
window.addEventListener('beforeinstallprompt', (e) => {
  deferredPrompt = e;
});
const test = async () => {
  //@ts-ignore
  if (deferredPrompt !== null) {
    //@ts-ignore
    deferredPrompt.prompt();
    //@ts-ignore
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      deferredPrompt = null;
    }
  }
}
let socket: undefined | any = undefined
function App() {
  const handle = useFullScreenHandle();
  const [lobbyData, setLobbyData] = useState({ roomNumber: null, players: [], gameData: undefined })
  const [featureToggles, setFeatureToggles] = useState<FeatureToggles>({})
  const [socketData, setSocketData] = useState<{ isConnected: boolean }>({ isConnected: isMobile })
  const serverDataRef = useRef<any>(null)
  useEffect(() => {
    if (socketData.isConnected) {
      postAnalytics("host_connected_to_socket_server_successfully", { link: window.location.href })
    }
  }, [socketData.isConnected])
  //  const navigate = useNavigate()
  useEffect(() => {
    getAddress()
  }, []);
  const getAddress = async () => {
    try {
      let serverData = await getServerData()
      serverDataRef.current = serverData
      socketService.Setup(serverData.address)
      axios.defaults.baseURL = serverData.address
      setFeatureToggles(serverData.featureToggles)
      if (isSocketRoute) {
        subscribeToLobbyEvents()
      }
    } catch (e) {
      setTimeout(getAddress, 1000)
    }

  }
  const subscribeToLobbyEvents = () => {
    const { socket } = socketService
    socket.on('connect', handleConnect);
    socket.on('lobbyChanged', handleLobbyChanged);
    socket.on('lobbyCreated', handleLobbyCreated);
    socket.on('disconnect', handleDisconnect);
  }
  const unsubscribeToLobbyEvents = () => {
    const { socket } = socketService
    socket.off('connect', handleConnect);
    socket.off('lobbyChanged', handleLobbyChanged);
    socket.off('lobbyCreated', handleLobbyCreated);
    socket.off('disconnect', handleDisconnect);
  }
  const handleDisconnect = () => {
    unsubscribeToLobbyEvents()
    postAnalytics('host_got_disconnect', { link: window.location.href })
    window.location.href = window.location.protocol + "//" + window.location.host
    setSocketData({ ...socketData, isConnected: false })
  }
  const handleLobbyCreated = (data: any) => {
    setLobbyData(data)
  }
  const handleLobbyChanged = (data: any) => {
    setLobbyData(data)
  }
  const handleConnect = () => {
    postAnalytics('host_got_connected_event_from_server', { link: window.location.href, serverUrl: serverDataRef.current?.address })
    setSocketData({ isConnected: true })
  }
  if (isSocketRoute && !socketData.isConnected) {
    return <div className={classes.cantStart}><Logo /></div>
  }
  const KartGameIntroduction = <>
    <h1>Gear Up for the Race! Race N Smash - Your Thrilling Mario Kart Alternative on PC</h1>
    <p>Calling all PC gamers with a need for speed and a dash of friendly competition!  Love the chaotic fun of Mario Kart but can't play it on your PC? Look no further than Race N Smash, the exhilarating new kart racing game from PlayCloud that brings the Mario Kart experience straight to your desktop!</p>

    <h2>Unleash the Racing Frenzy!</h2>
    <p>Race N Smash captures the essence of classic kart racing games. Choose from a cast of wacky and colorful characters, each with their own unique kart. Master the art of drifting around tight corners, unleash a barrage of powerups to outmaneuver your opponents, and dominate the leaderboards across a variety of exciting tracks.  Just like Mario Kart, Race N Smash is all about the thrill of the race, the strategic use of powerups, and the hilarious chaos that ensues when friends and family clash on the track.</p>

    <h2>Why Choose Race N Smash?</h2>
    <ul>
      <li>Fast-Paced Arcade Racing: Experience the pure joy of arcade-style racing with intuitive controls and a focus on fun over extreme realism.</li>
      <li>Powerup Mayhem: Utilize a variety of powerups to gain an edge, slow down opponents, or unleash devastating attacks. Remember, in Race N Smash, first place is never guaranteed!</li>
      <li>A Cast of Colorful Characters: Choose your favorite racer from a diverse roster, each with their own unique personality and kart.</li>
      <li>Thrilling Tracks: Race across a variety of tracks, from tropical beaches to futuristic cityscapes, each offering unique challenges and shortcuts to discover.</li>
      <li>Solo or Multiplayer Mayhem: Race against AI opponents to hone your skills or challenge your friends and family in epic online multiplayer battles.</li>
    </ul>

    <h2>Ready to Race? Just scan the QR to play Race N Smash Today!</h2>
    <p>So, what are you waiting for? Play Race N Smash today and experience the ultimate Mario Kart alternative for PC!  With its vibrant graphics, intuitive controls, and side-splitting powerup action, Race N Smash is the perfect game for anyone who wants to unleash their inner racer and have a blast.  Visit the PlayCloud website or your favorite digital storefront to download Race N Smash and join the racing revolution!</p>

  </>
  const PartyGamesIntroduction = <>  <h1>Ditch the Cartridges: PlayCloud Party Games - The FREE Mario Party Alternative for Up to 8 Players!</h1>

    <p>Calling all party animals! Craving that classic couch co-op fun but tired of wrestling for controllers and digging out old Mario Party cartridges? Look no further than PlayCloud Party Games, the innovative, FREE alternative to your favorite Mario Party alternative.</p>

    <h2>Why PlayCloud Party Games is the Ultimate Mario Party Alternative</h2>

    <ul>
      <li><b>Free to Play:</b> PlayCloud Party Games won't break the bank. Unlike some paid party games, it's completely free to play, making it a fantastic <b>Mario Party alternative</b> for budget-minded gamers.</li>
      <li><b>Massive Multiplayer Mayhem:</b> Up to 8 players can join the party, making PlayCloud Party Games perfect for large gatherings. It's the perfect <b>Mario Party alternative</b> for those who love big bashes!</li>
      <li><b>Phone in Your Hand, Fun on the Screen:</b> Ditch the bulky controllers! PlayCloud Party Games utilizes the power you already have – your smartphone! Simply connect to the game through your phone's browser and use it as your controller. It's a convenient and accessible <b>Mario Party alternative</b> for everyone.</li>
      <li><b>A Library of Laughter:</b> PlayCloud Party Games boasts a diverse library of exciting minigames, ensuring there's something for everyone. From fast-paced racing to strategic challenges, prepare for endless entertainment – just like the best <b>Mario Party alternative</b> should!</li>
    </ul>

    <h2>Exciting Minigames - A Fun Twist on Classic Mario Party Staples</h2>

    <ul>
      <li>Pass the Bomb: Keep the hot potato moving – don't get caught holding it when it explodes!</li>
      <li>Cart Racing: Put your pedal to the metal and race your friends to the checkered flag, just like a classic Mario Party .</li>
      <li>Ice Hockey: Lace up your virtual skates and battle it out for hockey supremacy.</li>
      <li>Capture the Flag: Teamwork makes the dream work! Strategize with your teammates to capture the enemy flag, a unique twist on the Mario Party alternative formula.</li>
      <li>Other 10+ minigames</li>
    </ul>

    <h2>Why Choose PlayCloud Party Games?</h2>

    <p><b>So ditch the nostalgia and embrace the future!</b> PlayCloud Party Games provides a refreshing twist on the classic party game experience, offering a free, accessible, and endlessly entertaining <b>Mario Party alternative</b> for you and your whole crew. </p>

    <p><b>Ready to get the party started?</b> Simply grab your friends, smartphones, and head over to PlayCloud Party Games. It's time to create new memories (and maybe a little friendly competition) without breaking the bank with this fantastic <b>Mario Party alternative</b>!</p>


  </>
  const PartyGamesIntroduction2 = <>
    <h1>PlayCloud Party Games - The mini games frenzy, if you are looking for games like Mario Party</h1>

    <p>Remember the epic Mario Party battles with friends? Want to play minigames similar to Mario Party but with a modern twist? Look no further than PlayCloud Party Games, the innovative, FREE alternative that brings the party roaring back into your living room.</p>

    <h2>Play party games - Without needing any controllers or a console - Minigames like in mario party </h2>

    <ul>
      <li><b>Mini game madness</b> Like mario party, PlayCloud party games is based around fun solo and team minigames</li>
      <li><b>Free and Fun for All:</b> Unlike some mario party,  PlayCloud Party Games is completely free to play. No hidden costs, just pure party game fun!</li>
      <li><b>Massive Multiplayer Mayhem:</b> Party larger than ever! Up to 8 players can join the PlayCloud Party Games fun, making it ideal for those who love the chaotic energy of classic party games.</li>
      <li><b>Phones in Hand, Fun on the Screen:</b> Ditch the bulky controllers and crowded couch! PlayCloud Party Games utilizes your smartphone! Simply connect through your phone's browser and use it as your controller. It's a convenient and accessible option for everyone.</li>
      <li><b>A Library of Laughter:</b> PlayCloud Party Games boasts a diverse library of exciting mini-games, similar to Mario Partys type of minigames. Prepare for endless entertainment with strategic challenges and fast-paced races.</li>
    </ul>

    <h2>Over 15 exciting Minigames, Team mini games similar to mario party -</h2>

    <h2>Why Choose PlayCloud Party Games for Your Next Game Night?</h2>

    <p><b>So Consider PlayCloud Party Games!</b> It offers a refreshing twist on the classic experience, bringing the fun, competition, and laughter to your next game night – all for free and accessible with your trusty smartphones.</p>

    <p><b>Ready to get the party started?</b> Gather your friends, grab your phones, and head over to PlayCloud Party Games. It's time to create new memories (and maybe a little friendly competition) without breaking the bank!</p>
  </>
  const PartyGamesIntroduction3 = <>
    <h1>PlayCloud Party Games: A Mario Party Alternative Like No Other</h1>
    <p>In the realm of multiplayer gaming, few titles have captured the essence of frenetic fun and friendly competition quite like Mario Party. However, for those seeking a fresh twist on the classic party game formula without the need for downloads or controllers, there's a new contender in town: PlayCloud Party Games. This innovative gaming platform offers a seamless and accessible experience that's similar to Mario Party but with unique advantages.</p>

    <h2>No Downloads, No Controllers, Just Fun</h2>
    <p>One of the standout features of PlayCloud Party Games is its accessibility. Similar to Mario Party, it requires no lengthy downloads or specialized controllers. With just a simple scan of a QR code, players can instantly jump into the action using their smartphones or tablets. This eliminates the need for additional hardware or cumbersome setup, making it ideal for impromptu gatherings or casual gaming sessions.</p>

    <h2>A Diverse Selection of Games</h2>
    <p>Similar to Mario Party, PlayCloud Party Games offers a diverse selection of mini-games designed to test players' skills, strategy, and luck. From fast-paced racing challenges to brain-teasing puzzles, there's something for everyone to enjoy. Whether you're competing against friends or teaming up for cooperative gameplay, the variety of mini-games ensures that every session is packed with excitement and surprises.</p>

    <h2>Seamless Online Integration</h2>
    <p>While Mario Party has traditionally been limited to local multiplayer, PlayCloud Party Games takes the experience to the next level with seamless online integration. Similar to Mario Party, whether you're playing with friends across town or strangers from around the world, the platform's online multiplayer functionality allows for endless opportunities to connect and compete. With robust matchmaking options and integrated voice chat, coordinating game sessions has never been easier.</p>

    <h2>Constant Updates and New Content</h2>
    <p>In the world of gaming, longevity is key, and PlayCloud Party Games delivers with regular updates and new content. Similar to Mario Party, from fresh mini-games to themed events and seasonal challenges, there's always something new to discover. This commitment to ongoing support ensures that the gameplay experience remains engaging and exciting for both new and returning players alike.</p>

    <h2>A Mario Party Alternative, But Better</h2>
    <p>While Mario Party has long been hailed as the ultimate party game experience, PlayCloud Party Games offers a compelling alternative that's similar to Mario Party but with unique advantages. With its accessible gameplay, diverse selection of mini-games, seamless online integration, and regular updates, PlayCloud Party Games raises the bar for what a modern party game can be. Whether you're a seasoned Mario Party veteran or a newcomer looking for a fresh gaming experience, PlayCloud Party Games is sure to delight and entertain.</p>

    <p>In conclusion, for those seeking a Mario Party-like experience without the hassle of downloads or controllers, PlayCloud Party Games stands as a worthy alternative. With its accessible gameplay, diverse selection of mini-games, seamless online integration, and commitment to ongoing support, it offers an unparalleled party gaming experience that's sure to keep players coming back for more. So why wait? Gather your friends, scan that QR code, and let the fun begin!</p>
  </>
  const DrawingGameForParties = <>
      <header>
        <h1>Drawing Game for Parties: The Ultimate Icebreaker!</h1>
        <p>Looking for a fun, interactive activity for your next party? Our drawing game is the perfect way to bring friends and family together for hilarious, creative fun!</p>
    </header>

    <section>
        <h2>Why Our Game is Perfect for Parties</h2>
        <p>Our drawing game is designed to make your parties unforgettable. Here’s why it’s a hit:</p>
        <ul>
            <li><strong>Hilarious Fun</strong>: Watch your friends’ drawings turn into laugh-out-loud moments.</li>
            <li><strong>Easy to Play</strong>: No complicated rules – just pick up and play!</li>
            <li><strong>Interactive</strong>: Everyone gets involved, whether they’re drawing or guessing.</li>
            <li><strong>Flexible</strong>: Play with small or large groups – perfect for any party size.</li>
            <li><strong>Free to Play</strong>: No downloads or subscriptions required.</li>
        </ul>
    </section>

    <section>
        <h2>How It Works</h2>
        <p>Our drawing game is simple, fast, and perfect for parties. Here’s how to play:</p>
        <ol>
            <li><strong>Start a Game</strong>: Create a room and invite your friends to join.</li>
            <li><strong>Draw</strong>: Get a random word and sketch it on your device. Be as creative (or silly) as you want!</li>
            <li><strong>Guess</strong>: Other players guess what you’re drawing. The faster they guess, the more points you both earn!</li>
            <li><strong>Compete</strong>: Earn points and climb the leaderboard. The player with the most points wins bragging rights!</li>
        </ol>
    </section>

    <section>
        <h2>What Makes Our Game Stand Out?</h2>
        <p>Our game isn’t just fun – it’s packed with features that make it perfect for parties:</p>
        <ul>
            <li><strong>3-Stroke Duel</strong>: Draw your word using only 3 brush strokes. Can you make it recognizable?</li>
            <li><strong>Speed Master</strong>: Draw as many words as possible in 60 seconds. Quick thinking wins!</li>
            <li><strong>Custom Words</strong>: Add your own words for personalized party fun.</li>
            <li><strong>Team Play</strong>: Split into teams and compete for the highest score.</li>
        </ul>
    </section>

    <section>
        <h2>What Players Are Saying</h2>
        <blockquote>
            <p>"This game is a party essential! We played it at my birthday, and everyone couldn’t stop laughing."</p>
            <cite>– Lisa, Party Host</cite>
        </blockquote>
        <blockquote>
            <p>"The 3-Stroke Duel mode is hilarious! It’s the perfect icebreaker for any gathering."</p>
            <cite>– Mike, Social Butterfly</cite>
        </blockquote>
    </section>

    <section>
        <h2>Ready to Spice Up Your Party?</h2>
        <p>Gather your friends, grab your devices, and get ready for the most fun you’ve ever had at a party. Our drawing game is free, easy to play, and guaranteed to bring laughter and creativity to your next gathering!</p>
    </section>

    <section>
        <h2>Frequently Asked Questions</h2>
        <details>
            <summary>How many players can join?</summary>
            <p>Our game supports up to 8 players, making it perfect for small or large parties.</p>
        </details>
        <details>
            <summary>Can I play on different devices?</summary>
            <p>Yes! Our game works on phones, tablets, and computers for maximum flexibility.</p>
        </details>
        <details>
            <summary>Is the game suitable for kids?</summary>
            <p>Absolutely! Our game is family-friendly and safe for players of all ages.</p>
        </details>
    </section>

  </>
    const DrawingGameForFamilies = <>
        <header>
        <h1>Family Drawing Game: Fun for All Ages!</h1>
        <p>Looking for a game that brings the whole family together? Our family drawing game is the perfect way to enjoy creative, hilarious fun with everyone – from kids to grandparents!</p>
    </header>

    <section>
        <h2>Why Families Love Our Game</h2>
        <p>Our family drawing game is designed to create unforgettable moments. Here’s why it’s a hit:</p>
        <ul>
            <li><strong>Family-Friendly Fun</strong>: Safe and enjoyable for players of all ages.</li>
            <li><strong>Easy to Play</strong>: Simple rules that everyone can understand.</li>
            <li><strong>Creative Challenges</strong>: Unique modes like "3-Stroke Duel" and "Speed Master" keep things exciting.</li>
            <li><strong>Interactive</strong>: Everyone gets involved, whether they’re drawing or guessing.</li>
            <li><strong>Free to Play</strong>: No downloads or subscriptions required.</li>
        </ul>
    </section>

    <section>
        <h2>How It Works</h2>
        <p>Our family drawing game is simple, fast, and perfect for family game nights. Here’s how to play:</p>
        <ol>
            <li><strong>Start a Game</strong>: Create a room and invite your family members to join.</li>
            <li><strong>Draw</strong>: Get a random word and sketch it on your device. Be as creative (or silly) as you want!</li>
            <li><strong>Guess</strong>: Other players guess what you’re drawing. The faster they guess, the more points you both earn!</li>
            <li><strong>Compete</strong>: Earn points and climb the leaderboard. The player with the most points wins bragging rights!</li>
        </ol>
    </section>

    <section>
        <h2>What Makes Our Game Special?</h2>
        <p>Our game isn’t just fun – it’s packed with features that make it perfect for families:</p>
        <ul>
            <li><strong>3-Stroke Duel</strong>: Draw your word using only 3 brush strokes. Can you make it recognizable?</li>
            <li><strong>Speed Master</strong>: Draw as many words as possible in 60 seconds. Quick thinking wins!</li>
            <li><strong>Custom Words</strong>: Add your own words for personalized family fun.</li>
            <li><strong>Team Play</strong>: Split into teams and compete for the highest score.</li>
        </ul>
    </section>

    <section>
        <h2>What Families Are Saying</h2>
        <blockquote>
            <p>"This game is a family favorite! We play it every game night, and it’s always a hit with the kids and grandparents."</p>
            <cite>– Sarah, Mom of Two</cite>
        </blockquote>
        <blockquote>
            <p>"The 3-Stroke Duel mode is hilarious! It’s the perfect way to get everyone laughing together."</p>
            <cite>– Tom, Dad and Game Enthusiast</cite>
        </blockquote>
    </section>

    <section>
        <h2>Ready for Family Fun?</h2>
        <p>Gather your family, grab your devices, and get ready for the most fun you’ve ever had together. Our family drawing game is free, easy to play, and guaranteed to bring laughter and creativity to your next game night!</p>
    </section>

    <section>
        <h2>Frequently Asked Questions</h2>
        <div>
            <h2>How many players can join?</h2>
            <p>Our game supports up to 8 players, making it perfect for small or large families.</p>
        </div>
        <div>
            <h2>Can I play on different devices?</h2>
            <p>Yes! Our game works on phones, tablets, and computers for maximum flexibility.</p>
        </div>
        <div>
            <h2>Is the game suitable for young kids?</h2>
            <p>Absolutely! Our game is family-friendly and safe for players of all ages.</p>
        </div>
    </section>
</>
const DrawAndGuessGame = <>
    <header>
        <h1>Draw and Guess Game: Creative Fun for Everyone!</h1>
        <p>Looking for the ultimate draw and guess game? Play our multiplayer drawing and guessing game with friends and family. Perfect for parties, game nights, or casual fun!</p>
    </header>

    <section>
        <h2>Why Choose Our Draw and Guess Game?</h2>
        <p>Our game is designed to bring people together through creativity and laughter. Here’s why players love it:</p>
        <ul>
            <li><strong>Multiplayer Fun</strong>: Play with friends and family in real-time.</li>
            <li><strong>Creative Challenges</strong>: Unique modes like "3-Stroke Duel" and "Speed Master" keep things exciting.</li>
            <li><strong>Family-Friendly</strong>: Safe and enjoyable for players of all ages.</li>
            <li><strong>Easy to Play</strong>: No complicated rules – just pick up and play!</li>
            <li><strong>Free to Join</strong>: No downloads or subscriptions required.</li>
        </ul>
    </section>

    <section>
        <h2>How It Works</h2>
        <p>Our draw and guess game is simple, fast, and endlessly entertaining. Here’s how to play:</p>
        <ol>
            <li><strong>Start a Game</strong>: Create a room and invite your friends or family to join.</li>
            <li><strong>Draw</strong>: Get a random word and sketch it on your device. Be as creative as you can!</li>
            <li><strong>Guess</strong>: Other players guess what you’re drawing. The faster they guess, the more points you both earn!</li>
            <li><strong>Compete</strong>: Earn points and climb the leaderboard. The player with the most points wins!</li>
        </ol>
    </section>

    <section>
        <h2>What Makes Our Game Unique?</h2>
        <p>Our draw and guess game isn’t just about drawing – it’s about creativity, strategy, and fun. Here’s what sets us apart:</p>
        <ul>
            <li><strong>3-Stroke Duel</strong>: Draw your word using only 3 brush strokes. Can you make it recognizable?</li>
            <li><strong>Speed Master</strong>: Draw as many words as possible in 60 seconds. Quick thinking wins!</li>
            <li><strong>Custom Words</strong>: Add your own words for personalized fun.</li>
            <li><strong>Team Play</strong>: Split into teams and compete for the highest score.</li>
        </ul>
    </section>

    <section>
        <h2>What Players Are Saying</h2>
        <blockquote>
            <p>"This is the best draw and guess game I’ve ever played! It’s so much fun with friends and family."</p>
            <cite>– Emily, Casual Gamer</cite>
        </blockquote>
        <blockquote>
            <p>"The 3-Stroke Duel mode is hilarious! It’s the perfect way to get everyone laughing together."</p>
            <cite>– Jake, Party Enthusiast</cite>
        </blockquote>
    </section>

    <section>
        <h2>Ready to Start Drawing and Guessing?</h2>
        <p>Join thousands of players enjoying the best draw and guess game. It’s free, fun, and easy to start!</p>
        <p>Gather your friends and family, and start playing today!</p>
    </section>

    <section>
        <h2>Frequently Asked Questions</h2>
        <div>
            <h2>How many players can join?</h2>
            <p>Our game supports up to 8 players, making it perfect for parties or family nights.</p>
        </div>
        <div>
            <h2>Can I play on different devices?</h2>
            <p>Yes! Our game works on phones, tablets, and computers for maximum flexibility.</p>
        </div>
        <div>
            <h2>Is the game suitable for kids?</h2>
            <p>Absolutely! Our game is family-friendly and safe for players of all ages.</p>
        </div>
    </section>

</>
const DrawAndGuessGameSpanish = <>
<header>
    <h1>¡Juego de Dibujar y Adivinar: Diversión Creativa para Todos!</h1>
    <p>¿Buscas el mejor juego de dibujar y adivinar? Juega nuestro juego multijugador de dibujo y adivinanza con amigos y familiares. ¡Perfecto para fiestas, noches de juego o diversión casual!</p>
</header>

<section>
    <h2>¿Por qué elegir nuestro juego de Dibujar y Adivinar?</h2>
    <p>Nuestro juego está diseñado para unir a las personas a través de la creatividad y la risa. Aquí están las razones por las que los jugadores lo aman:</p>
    <ul>
        <li><strong>Diversión Multijugador</strong>: Juega con amigos y familiares en tiempo real.</li>
        <li><strong>Desafíos Creativos</strong>: Modos únicos como "Duelo de 3 Trazos" y "Maestro de la Velocidad" mantienen la emoción.</li>
        <li><strong>Apto para la Familia</strong>: Seguro y divertido para jugadores de todas las edades.</li>
        <li><strong>Fácil de Jugar</strong>: Sin reglas complicadas, ¡solo elige y juega!</li>
        <li><strong>Gratis para Unirse</strong>: Sin descargas ni suscripciones requeridas.</li>
    </ul>
</section>

<section>
    <h2>¿Cómo Funciona?</h2>
    <p>Nuestro juego de dibujar y adivinar es simple, rápido y sumamente entretenido. Así es como se juega:</p>
    <ol>
        <li><strong>Inicia un Juego</strong>: Crea una sala e invita a tus amigos o familiares a unirse.</li>
        <li><strong>Dibuja</strong>: Recibe una palabra aleatoria y dibújala en tu dispositivo. ¡Sé tan creativo como puedas!</li>
        <li><strong>Adivina</strong>: Los demás jugadores intentan adivinar tu dibujo. ¡Cuanto más rápido acierten, más puntos ganan ambos!</li>
        <li><strong>Compite</strong>: Gana puntos y sube en la tabla de posiciones. ¡El jugador con más puntos gana!</li>
    </ol>
</section>

<section>
    <h2>¿Qué hace único a nuestro juego?</h2>
    <p>Nuestro juego de dibujar y adivinar no es solo sobre dibujar, sino también sobre creatividad, estrategia y diversión. Esto es lo que nos diferencia:</p>
    <ul>
        <li><strong>Duelo de 3 Trazos</strong>: Dibuja tu palabra usando solo 3 trazos. ¿Podrán adivinarlo?</li>
        <li><strong>Maestro de la Velocidad</strong>: Dibuja tantas palabras como puedas en 60 segundos. ¡Piensa rápido!</li>
        <li><strong>Palabras Personalizadas</strong>: Agrega tus propias palabras para una diversión más personalizada.</li>
        <li><strong>Juego en Equipo</strong>: Divide en equipos y compite por la puntuación más alta.</li>
    </ul>
</section>

<section>
    <h2>¡Lo que dicen los jugadores!</h2>
    <blockquote>
        <p>"¡Este es el mejor juego de dibujar y adivinar que he jugado! ¡Es muy divertido con amigos y familiares!"</p>
        <cite>– Emily, Jugadora Casual</cite>
    </blockquote>
    <blockquote>
        <p>"¡El modo Duelo de 3 Trazos es divertidísimo! Es perfecto para reír con todos."</p>
        <cite>– Jake, Fanático de las Fiestas</cite>
    </blockquote>
</section>

<section>
    <h2>¿Listo para empezar a dibujar y adivinar?</h2>
    <p>¡Únete a miles de jugadores disfrutando del mejor juego de dibujar y adivinar! Es gratis, divertido y fácil de empezar.</p>
    <p>¡Reúne a tus amigos y familiares y comienza a jugar hoy!</p>
</section>

<section>
    <h2>Preguntas Frecuentes</h2>
    <div>
        <h2>¿Cuántos jugadores pueden unirse?</h2>
        <p>Nuestro juego admite hasta 8 jugadores, ideal para fiestas o noches en familia.</p>
    </div>
    <div>
        <h2>¿Puedo jugar en diferentes dispositivos?</h2>
        <p>¡Sí! Nuestro juego funciona en teléfonos, tabletas y computadoras para mayor flexibilidad.</p>
    </div>
    <div>
        <h2>¿Es apto para niños?</h2>
        <p>¡Por supuesto! Nuestro juego es seguro y apto para todas las edades.</p>
    </div>
</section>
</>
  const DrawingGameForTvWithPhones = <>
      <header>
        <h1>The Ultimate Drawing Game for TV with Phones</h1>
        <p>Transform your TV into a canvas and your phones into brushes! Our drawing game is the perfect way to bring friends and family together for hours of creative fun.</p>
    </header>

    <section>
        <h2>Why Our Game Stands Out</h2>
        <p>Looking for a drawing game that’s more than just fun? Here’s why players love our game:</p>
        <ul>
            <li><strong>TV & Phone Integration</strong>: Cast the game to your TV and use phones to draw and guess – no extra controllers needed!</li>
            <li><strong>Fast-Paced Rounds</strong>: Keep the excitement high with quick turns and instant results.</li>
            <li><strong>Creative Challenges</strong>: Unique modes like "3-Stroke Duel" and "Speed Master" test your artistic skills.</li>
            <li><strong>Family-Friendly Fun</strong>: Safe and enjoyable for players of all ages.</li>
            <li><strong>Free to Play</strong>: No hidden fees or subscriptions – just pure fun!</li>
        </ul>
    </section>

    <section>
        <h2>How to Play</h2>
        <p>Getting started is easy and fun. Here’s how it works:</p>
        <ol>
            <li><strong>Set Up</strong>: Cast the game to your TV and connect your phones using the game code.</li>
            <li><strong>Draw</strong>: Receive a random word and sketch it on your phone. Be as creative as you can!</li>
            <li><strong>Guess</strong>: Other players guess what you’re drawing. The faster they guess, the more points you both earn!</li>
            <li><strong>Compete</strong>: Earn crowns by winning rounds. The first player to collect 3 crowns wins the game!</li>
        </ol>
    </section>

    <section>
        <h2>Exciting Game Modes</h2>
        <p>Our game isn’t just about drawing – it’s about creativity, speed, and strategy. Check out our unique modes:</p>
        <ul>
            <li><strong>3-Stroke Duel</strong>: Draw your word using only 3 brush strokes. Can you make it recognizable?</li>
            <li><strong>Speed Master</strong>: Draw as many words as possible in 60 seconds. Quick thinking wins!</li>
            <li><strong>Team Play</strong>: Split into teams and compete for the highest score.</li>
        </ul>
    </section>

    <section>
        <h2>What Players Are Saying</h2>
        <blockquote>
            <p>"This is the best drawing game for TV with phones! We play it every family game night, and it’s always a hit!"</p>
            <cite>– Sarah, Mom of Two</cite>
        </blockquote>
        <blockquote>
            <p>"I love the 3-Stroke Duel mode – it’s so challenging and fun! Highly recommend this game."</p>
            <cite>– Jake, Casual Gamer</cite>
        </blockquote>
    </section>

    <section>
        <h2>Ready to Start Drawing?</h2>
        <p>Gather your friends and family, and dive into the ultimate drawing game for TV with phones. It’s free, easy to play, and guaranteed to bring laughter and creativity to your next gathering!</p>
    </section>

    <section>
        <h2>Frequently Asked Questions</h2>
        <div>
            <summary>How many players can join?</summary>
            <p>Our game supports up to 8 players, making it perfect for parties or family nights.</p>
        </div>
        <div>
            <summary>Do I need special equipment?</summary>
            <p>Nope! Just a TV that supports casting and smartphones for each player.</p>
        </div>
        <div>
            <summary>Is the game suitable for kids?</summary>
            <p>Absolutely! Our game is family-friendly and safe for players of all ages.</p>
        </div>
    </section>
  </>
  const GarticPhoneAlternative = <>
   <header>
        <h1>The Best Gartic Phone Alternative: Faster, Funnier, and More Creative!</h1>
        <p>Looking for a Gartic Phone alternative that’s faster, more engaging, and packed with unique features? Try our game! Perfect for parties, family nights, or just casual fun with friends.</p>
    </header>

    <section>
        <h2>Why Choose Our Game Over Gartic Phone?</h2>
        <p>Here’s why our game is the <strong>ultimate Gartic Phone alternative</strong>:</p>
        <ul>
            <li><strong>Faster Rounds</strong>: No long waits – our game keeps the action moving with quick turns and instant results.</li>
            <li><strong>Unique Challenges</strong>: Special rounds like "3-Stroke Duel" and "Speed Master" add excitement and creativity.</li>
            <li><strong>Crown System</strong>: Earn crowns for winning rounds. First to 3 crowns wins the game!</li>
            <li><strong>TV & Phone Play</strong>: Play on TV with phones as controllers for a shared, immersive experience.</li>
            <li><strong>Family-Friendly</strong>: Safe and fun for players of all ages.</li>
        </ul>
    </section>

    <section>
        <h2>How It Works</h2>
        <p>Our game takes the classic drawing and guessing formula and adds exciting twists:</p>
        <ol>
            <li><strong>Draw</strong>: Get a random word and draw it on your phone.</li>
            <li><strong>Guess</strong>: Other players guess what you’re drawing. The faster they guess, the more points you both earn!</li>
            <li><strong>Win Crowns</strong>: Earn crowns for winning rounds. First to 3 crowns wins the game!</li>
        </ol>
    </section>

    <section>
        <h2>What Makes Us Different?</h2>
        <ul>
            <li><strong>3-Stroke Duel</strong>: Draw with only 3 brush strokes for double points!</li>
            <li><strong>Speed Master</strong>: Draw as many words as possible in 60 seconds. Quick thinking wins!</li>
            <li><strong>Shared Experience</strong>: Play on TV with phones for a fun, group-friendly setup.</li>
        </ul>
    </section>

    <section>
        <h2>Ready to Play?</h2>
        <p>Join thousands of players enjoying the best Gartic Phone alternative. It’s free, fun, and easy to start!</p>
        <p>Start playing now and see why everyone loves our game!</p>
    </section>

    <section>
        <h2>FAQ</h2>
        <div>
            <h2>How many players can join?</h2>
            <p>Up to 8 players! Perfect for parties or family nights.</p>
        </div>
        <div>
            <h2>Can I play on TV?</h2>
            <p>Yes! Cast to your TV and use phones to draw and guess.</p>
        </div>
        <div>
            <h2>Is the game free?</h2>
            <p>Yes, our game is completely free to play!</p>
        </div>
    </section>
  </>
  const SkribblIoAlternative = <>
      <header>
        <h1>Skribbl.io Alternative: Win Crowns in 3 Fast-Paced Drawing Rounds!</h1>
        <p>Outsmart friends in 3 unique rounds! Draw, guess, and race to earn crowns. First to 3 crowns wins! 🏆</p>
    </header>

    <section id="game-modes">
        <h2>How It Works: 3 Rounds, 1 Champion</h2>
        
        <div>
            <h3>Round 1: Classic Chaos</h3>
            <ul>
                <li>Draw your secret word on your phone.</li>
                <li>Everyone guesses – the faster they guess, the more points you BOTH earn!</li>
                <li>Maximize guesses to climb the leaderboard.</li>
            </ul>
        </div>

        <div>
            <h3>Round 2: Speed Master</h3>
            <ul>
                <li>You’re the artist – draw as many words as possible in 60 seconds!</li>
                <li>First correct guesser wins points. No second chances!</li>
                <li>Quick hands and sharp minds dominate.</li>
            </ul>
        </div>

        <div>
            <h3>Round 3: 3-Stroke Duel</h3>
            <ul>
                <li>Draw your word with ONLY 3 brush strokes.</li>
                <li>Points are DOUBLED – creativity wins!</li>
                <li>Can you guess a cactus in 3 lines? 🌵</li>
            </ul>
        </div>
    </section>

    <section id="crowns">
        <h2>Earn Crowns, Become the Ultimate Artist</h2>
        <p>After 3 rounds, the player with the most points wins a crown. First to <strong>3 crowns</strong> wins the game!</p>
    </section>

    <section id="faq">
        <h2>FAQ</h2>
        <p>Up to 8 players! Perfect for parties or family nights.</p>
        <p>Yes! Cast to your TV and use phones to draw/guess.</p>

    </section>
  </>
  const PartyGameIntroduction2Spanish = <>
    <p>¿Recuerdas las épicas batallas de Mario Party con amigos? ¿Anhelas juegos similares a Mario Party pero con un toque moderno? No busques más, PlayCloud Party Games es la innovadora alternativa GRATUITA que trae la fiesta de vuelta a tu sala de estar.</p>

    <h2>Por qué PlayCloud Party Games es perfecto para los fanáticos que buscan juegos como Mario Party</h2>

    <ul>
      <li><b>Gratis y divertido para todos:</b> A diferencia de algunos Mario Party, PlayCloud Party Games es completamente gratuito. ¡Sin costos ocultos, solo pura diversión de juegos de fiesta!</li>
      <li><b>Caos multijugador masivo:</b> ¡Fiestas más grandes que nunca! Hasta 8 jugadores pueden unirse a la diversión de PlayCloud Party Games, lo que lo hace ideal para aquellos que aman la energía caótica de los juegos de fiesta clásicos.</li>
      <li><b>Teléfonos en mano, diversión en la pantalla:</b> ¡Olvídate de los mandos voluminosos y el sofá abarrotado! ¡PlayCloud Party Games utiliza tu smartphone! Simplemente conéctate a través del navegador de tu teléfono y úsalo como control. Es una opción cómoda y accesible para todos.</li>
      <li><b>Una biblioteca de risas:</b> PlayCloud Party Games cuenta con una biblioteca diversa de emocionantes minijuegos, similar al tipo de minijuegos de Mario Party. Prepárate para un entretenimiento sin fin con desafíos estratégicos y carreras frenéticas.</li>
    </ul>

    <h2>Más de 15 emocionantes minijuegos, minijuegos en equipo, por ejemplo:</h2>

    <ul>
      <li><b>Pasa la Bomba:</b> Mantén la patata caliente en movimiento, ¡no te atrapen sosteniéndola cuando explote!</li>
      <li><b>Locura de Pelotas:</b> Acumula puntos maniobrando estratégicamente tu pelota a través de un laberinto.</li>
      <li><b>Carreras de Carritos:</b> Pisa el acelerador y corre con tus amigos hasta la bandera a cuadros.</li>
      <li><b>Hockey sobre Hielo:</b> Ata tus patines virtuales y lucha por la supremacía del hockey.</li>
      <li><b>Captura la Bandera:</b> ¡El trabajo en equipo hace que el sueño funcione! Crea estrategias con tus compañeros de equipo para capturar la bandera enemiga.</li>
    </ul>

    <h2>¿Por qué elegir PlayCloud Party Games para tu próxima noche de juegos?</h2>

    <p><b>¡Considera PlayCloud Party Games!</b> Ofrece un giro refrescante a la experiencia clásica, llevando la diversión, la competencia y la risa a tu próxima noche de juegos, todo gratis y accesible con tus confiables smartphones.</p>

    <p><b>¿Listo para comenzar la fiesta?</b> Reúne a tus amigos, agarra tus teléfonos y dirígete a PlayCloud Party Games. ¡Es hora de crear nuevos recuerdos (y tal vez un poco de competencia amistosa) sin gastar una fortuna!</p>
  </>
  const HockeyIntroduction = <>
    <h1>Welcome to PlayClouds Ice Hockey: The Ultimate Phone-Controlled Experience!</h1>
    <p>Unleash the excitement of ice hockey anytime, anywhere with PlayClouds Ice Hockey, the revolutionary game that turns your phone into the controller, bringing the thrill of the rink straight to your living room.</p>

    <h2>How It Works: Simple as Scan and Play</h2>
    <ol>
      <li><strong>Scan to Connect:</strong> Fire up your phone, scan the QR code displayed on your laptop or TV, and you're instantly in the game. No need for additional controllers – your smartphone is your ticket to the hockey action.</li>
      <li><strong>Gather Your Team:</strong> PlayClouds Ice Hockey supports up to 8 players, making it the perfect family and friends game. Challenge each other, form teams, and let the competition begin.</li>
      <li><strong>Free and Easy:</strong> PlayClouds Ice Hockey is not just a game; it's an experience. And the best part? It's absolutely free! No hidden charges, no in-app purchases – just pure hockey fun.</li>
    </ol>

    <h2>Key Features:</h2>
    <ul>
      <li><strong>Phone as Controller:</strong> Say goodbye to traditional controllers. Your smartphone becomes your hockey stick, adding a new level of engagement.</li>
      <li><strong>Multiplayer Madness:</strong> Up to 8 players can join, creating a lively and competitive atmosphere. Bond with family or challenge friends for ultimate bragging rights.</li>
      <li><strong>Vibrant Graphics:</strong> Stunning visuals bring the rink to life on your big screen, delivering an authentic hockey experience.</li>
      <li><strong>Dynamic Gameplay:</strong> From slapshots to breakaways, experience the intensity of hockey with responsive controls and realistic game physics.</li>
    </ul>

    <h2>The Evolution of Ice Hockey Games: A Brief History</h2>
    <p>Ice hockey games have come a long way since their inception. The journey from pixelated sprites to the immersive experience of PlayClouds Ice Hockey has been a remarkable one. Here's a brief look at the milestones:</p>

    <p><strong>Early Beginnings:</strong> The first ice hockey video games emerged in the late 1970s and early 1980s, featuring basic graphics and simple gameplay.</p>
    <p><strong>Console Era:</strong> The 1990s witnessed a surge in popularity with the release of hockey games on home consoles. Titles like NHL '94 became iconic for their realistic gameplay and player rosters.</p>
    <p><strong>Advancements in Technology:</strong> As technology advanced, so did the realism of ice hockey games. 3D graphics, detailed player models, and sophisticated physics engines elevated the gaming experience.</p>
    <p><strong>Mobile Revolution:</strong> In the smartphone era, hockey games became more accessible. PlayClouds Ice Hockey takes this accessibility to a new level by turning your phone into the ultimate controller.</p>

    <h2>Why PlayClouds Ice Hockey?</h2>
    <ul>
      <li><strong>Inclusive Fun:</strong> PlayClouds Ice Hockey eliminates the need for multiple controllers, making it accessible and inclusive for everyone.</li>
      <li><strong>Family-Friendly:</strong> Suitable for all ages, PlayClouds Ice Hockey is the perfect way to spend quality time with loved ones.</li>
      <li><strong>Tech-Driven Excitement:</strong> Embrace the future of gaming with our innovative use of smartphone technology.</li>
    </ul>

    <h2>Get Started in Three Easy Steps:</h2>
    <ol>
      <li><strong>Scan QR Code</strong></li>
      <li><strong>Connect Your Friends</strong></li>
      <li><strong>Experience the Thrill</strong></li>
    </ol>

  </>
  const HockeyLMIntroduction = <>
    <section >
      <p>Calling all hockey fans! Craving the thrill of a local multiplayer slap shot showdown but stuck at home? Yearning for epic competition that doesn't require controllers? Look no further than PlayCloud Ice Hockey, the revolutionary <b>local multiplayer</b> game that transforms your phones into hockey sticks!</p>
    </section>

    <section>
      <h2>Here's why PlayCloud Ice Hockey is the ultimate local multiplayer party starter:</h2>
      <ul>
        <li>
          <h3>Simple Setup, Instant Local Multiplayer Rivalry:</h3>
          <p>Ditch the controllers and grab your phones! Download the PlayCloud Ice Hockey app and mirror it to your TV. In seconds, you're ready to battle it out on the virtual rink in a thrilling local multiplayer experience.</p>
        </li>
        <li>
          <h3>Fast-Paced Action, Easy to Learn for Local Multiplayer Fun:</h3>
          <p>The intuitive swipe controls make PlayCloud Ice Hockey accessible for everyone in your local multiplayer game. Master the art of passing, shooting, and checking with minimal learning curve. But don't be fooled by the ease – the on-screen action is lightning-fast, demanding quick reflexes and strategic thinking in your local multiplayer battles.</p>
        </li>
        <li>
          <h3>Become Local Multiplayer Legends:</h3>
          <p>Gather your friends and family for a living room local multiplayer showdown. PlayCloud Ice Hockey is perfect for parties, game nights, or friendly sibling rivalries. Brag about your slapshot prowess or become the defensive wall your opponent can't crack in local multiplayer glory.</p>
        </li>
        <li>
          <h3>The Big Screen Advantage for Local Multiplayer:</h3>
          <p>Witness the action unfold on your TV in all its glory during your local multiplayer game. Experience the thrill of the game with clear visuals and immersive sound effects. Celebrate your goals and strategize with your teammates, all on the big screen for an enhanced local multiplayer experience.</p>
        </li>
      </ul>
    </section>
    <section >
      <p>PlayCloud Ice Hockey delivers competitive fun, easy accessibility, and big-screen thrills – all without leaving your living room.</p>
    </section>

  </>


  return (
    <FullScreen className={classes.fullscreen} handle={handle}>
      <FeatureToggleContext.Provider value={featureToggles}>
        <SocketContext.Provider value={socketData}>
          <LobbyContext.Provider value={lobbyData}>

            <BrowserRouter >
              <Routes>
                <Route path="/" element={<LaunchContainer handle={handle} />} />
                <Route path="/games" element={<GamesContainer handle={handle} />} />
                <Route path="/game" element={<WithCookieConsent><GameContainer handle={handle} /></WithCookieConsent>} />
                <Route path="/specificGame" element={<SpecificGameContainer handle={handle} />} />
                <Route path="/slipnbump" element={<SpecificGameContainer title={"Slip N Bump - CO OP up to 8 people"} predeterminedGameKey={'testgamelocal'} handle={handle} />} />
                <Route path="/bubblewargame" element={<SpecificGameContainer title={"Bubble War: A New Alternative to the Nostalgic Bubble Trouble Game"} predeterminedGameKey={'bubblewar'} handle={handle} />} />
                <Route path="/partygames" element={<SpecificGameContainer title={"PlayCloud party games - CO OP up to 8 people"} predeterminedGameKey={'minigames'} handle={handle} />} />
                <Route path={kartGamePath} element={<SpecificGameContainer
                  description='Looking for a Mario Kart fix on PC? Race N Smash delivers high-octane kart racing with fun characters, wacky powerups, and exciting tracks! Its the perfect Mario Kart alternative for PC gamers.'
                  keywords='Mario Kart alternative PC, PC kart racing games, PlayCloud games, Race N Smash, arcade racing, powerups, online multiplayer'
                  title={"Race N Smash: The Thrilling Mario Kart Alternative for PC!"}
                  introduction={KartGameIntroduction} predeterminedGameKey={'racegame'} handle={handle} />} />
                <Route path={partyGamesPath} element={<SpecificGameContainer
                  description='Looking for a Mario Party alternative? PlayCloud Party Games is a FREE, fun, and accessible party game for up to 8 players. Play with your smartphones on a computer or TV!"'
                  keywords='Mario party alternative, party games, free games, multiplayer games, PlayCloud Party Games, couch co-op, family games, game night'
                  title={"PlayCloud Party Games: The FREE Mario Party Alternative (Up to 8 Players!)"}
                  introduction={PartyGamesIntroduction} predeterminedGameKey={'minigames'} handle={handle} />} />
                <Route path={partyGamesSimilarPath2} element={<SpecificGameContainer
                  description='Looking for games like Mario Party or similar party games? PlayCloud Party Games is a FREE, fun, and accessible party game for up to 8 players. Play with your smartphones on a computer or TV!'
                  keywords='games like Mario Party, games similar to Mario Party, PlayCloud Party Games, multiplayer games, party games'
                  title={"Games like Mario Party with no controllers needed"}
                  introduction={PartyGamesIntroduction2} predeterminedGameKey={'minigames'} handle={handle} />} />
                <Route path={partyGamesSimilarPath} element={<SpecificGameContainer
                  description='Discover PlayCloud Party Games, an accessible and fun party game platform similar to Mario Party but with unique advantages. No downloads or controllers required.'
                  keywords='games like mario party, games similar to mario party, party games, free games, multiplayer games, PlayCloud Party Games, couch co-op, family games, game night'
                  title={"PlayCloud Party Games: A Mario Party Alternative Like No Other"}
                  introduction={PartyGamesIntroduction3} predeterminedGameKey={'minigames'} handle={handle} />} />
                <Route path={partyGameSpasnishPath} element={<SpecificGameContainer
                  description='¿Buscas juegos como Mario Party o juegos de fiesta similares? PlayCloud Party Games es un juego de fiesta GRATUITO, divertido y accesible para hasta 8 jugadores. ¡Juega con tus smartphones en una computadora o TV!'
                  keywords='juegos como mario party, juegos similares a mario party, juegos de fiesta, juegos gratuitos, juegos multijugador, PlayCloud Party Games, cooperativo local, juegos familiares, noche de juegos'
                  title={"PlayCloud Party Games: La alternativa GRATIS a Mario Party (¡Hasta 8 jugadores!)"}
                  introduction={PartyGameIntroduction2Spanish} predeterminedGameKey={'minigames'} handle={handle} />} />
                <Route path={iceHockeyPath} element={<SpecificGameContainer title={"Ice Hockey Local Multiplayer Game: PlayCloud Ice Hockey!"} description="PlayCloud Ice Hockey: The fun, local multiplayer ice hockey game for your phone! Battle friends on the big screen - No controllers needed!" introduction={HockeyLMIntroduction} keywords={'local multiplayer ice hockey game, local multiplayer games for phones, PlayCloud Ice Hockey, mobile hockey game'} predeterminedGameKey={'icehocky'} handle={handle} />} />
                <Route path={scribleIoAlternativePath} element={<SpecificGameContainer
                  description='The ultimate Skribbl.io alternative! Play our fast-paced drawing & guessing game with 3 unique rounds, crown victories, and competitive challenges. Perfect for friends and family!'
                  keywords='Skribbl.io alternative, drawing game with crowns, multiplayer drawing game, fast-paced drawing game, limited brush stroke game, family drawing challenge'
                  title={"Skribbl.io Alternative | 3-Round Drawing Game with Crown Wins"}
                  introduction={SkribblIoAlternative} predeterminedGameKey={'drawgame'} handle={handle} />} />
                  <Route path={garticPhoneAlternativePath} element={<SpecificGameContainer
                  description='The ultimate Gartic Phone alternative! Play our hilarious drawing and guessing game with unique twists, faster rounds, and more fun for friends and family'
                  keywords='Gartic Phone alternative, drawing and guessing game, multiplayer drawing game, family drawing game, party drawing game'
                  title={"Gartic Phone Alternative | Hilarious Drawing & Guessing Game"}
                  introduction={GarticPhoneAlternative} predeterminedGameKey={'drawgame'} handle={handle} />} />
                                    <Route path={drawingGameForTvWithPhonesPath} element={<SpecificGameContainer
                  description='Discover the best drawing game for TV with phones! Enjoy a fun, interactive multiplayer experience with friends and family. Perfect for parties, game nights, or casual fun.'
                  keywords='drawing game for TV with phones, TV drawing game, multiplayer drawing game, family drawing game, party drawing game'
                  title={"Best Drawing Game for TV with Phones | Interactive Multiplayer Fun"}
                  introduction={DrawingGameForTvWithPhones} predeterminedGameKey={'drawgame'} handle={handle} />} />
                               <Route path={drawingGameForPartiesPath} element={<SpecificGameContainer
                  description='The ultimate drawing game for parties! Bring friends and family together for hilarious, creative fun with our multiplayer drawing and guessing game. Perfect for any gathering!'
                  keywords='drawing game for parties, party drawing game, multiplayer drawing game, family drawing game, social drawing game'
                  title={"Drawing Game for Parties | Hilarious Multiplayer Fun"}
                  introduction={DrawingGameForParties} predeterminedGameKey={'drawgame'} handle={handle} />} />
                                                 <Route path={drawingGameForFamiliesPath} element={<SpecificGameContainer
                  description='The ultimate family drawing game! Bring everyone together for creative, hilarious fun with our multiplayer drawing and guessing game. Perfect for family game nights!'
                  keywords='family drawing game, drawing game for families, multiplayer drawing game, kids drawing game, family-friendly drawing game'
                  title={"Family Drawing Game | Fun for All Ages"}
                  introduction={DrawingGameForFamilies} predeterminedGameKey={'drawgame'} handle={handle} />} />
                                                                   <Route path={drawAndGuessGamePath} element={<SpecificGameContainer
                  description='The ultimate draw and guess game! Enjoy creative, hilarious fun with friends and family in this multiplayer drawing and guessing game. Perfect for parties, game nights, or casual fun!'
                  keywords='draw and guess game, multiplayer drawing game, drawing and guessing game, family drawing game, party drawing game'
                  title={"Draw and Guess Game | Creative Multiplayer Fun"}
                  introduction={DrawAndGuessGame} predeterminedGameKey={'drawgame'} handle={handle} />} />
                                                                                      <Route path={drawAndGuessGameSpanishPath} element={<SpecificGameContainer
                    description='¡El mejor juego de dibujar y adivinar! Disfruta de una diversión creativa e hilarante con amigos y familiares en este juego multijugador de dibujo y adivinanza. ¡Perfecto para fiestas, noches de juegos o diversión casual!'
                    keywords='juego de dibujar y adivinar, juego de dibujo multijugador, juego de dibujo y adivinanza, juego de dibujo familiar, juego de dibujo para fiestas'
                    title={"Juego de Dibujar y Adivinar | Diversión Multijugador Creativa"}
                  introduction={DrawAndGuessGameSpanish} predeterminedGameKey={'drawgame'} handle={handle} />} />
                <Route path="/capturetheflag" element={<SpecificGameContainer title={"PlayClouds Capture The Flag -  - CO OP up to 8 people"} predeterminedGameKey={'shootndodge'} handle={handle} />} />
                <Route path="/racensmash" element={<SpecificGameContainer title={"Race N Smash - 4 Player split screen power up racing game"} predeterminedGameKey={'racegame'} handle={handle} />} />
                <Route path="/roperagegame" element={<SpecificGameContainer predeterminedGameKey={'roperage'} handle={handle} />} />
                <Route path="/icehockygame" element={<SpecificGameContainer title={"PlayClouds ice hockey - up to 8 players"} introduction={HockeyIntroduction} predeterminedGameKey={'icehocky'} handle={handle} />} />
                <Route path="/ballfrenzygame" element={<SpecificGameContainer predeterminedGameKey={'ballfrenzy'} handle={handle} />} />
                <Route path="/drawgame" element={<SpecificGameContainer predeterminedGameKey={'drawgame'} handle={handle} />} />
                <Route path="/credits" element={<Credits />} />
                <Route path="/browser" element={<WithCookieConsent><BrowserControllerContainer handle={handle} /></WithCookieConsent>} />
                <Route path="/download" element={<DownloadControllerContainer handle={handle} />} />
                <Route path="/admin" element={<AdminContainer />} />
                {/**
                        <Route path="/introduction" element={<IntroductionContainer />} />
              <Route path="/introduction2" element={<Introduction2Container />} />
              <Route path="/introduction6" element={<Introduction6Container />} />
              <Route path="/online-games-to-play-with-friends" element={<Introduction3Container />} />
              <Route path="/play-games-with-friends" element={<Introduction4Container />}  />
              <Route path="/free-pc-games-to-play-with-friends" element={<Introduction5Container />} />
              <Route path="/couch-co-op-gaming" element={<Introduction7Container />} />
              <Route path="/airconsole-alternative" element={<Introduction8Container />} />
              <Route path="/jackbox-tv-alternative" element={<IntroductionJackboxContainer />} />
              <Route path="/jackboxtv-alternative" element={<IntroductionJackbox2Container />} />
              <Route path="/localmultiplayergames" element={<LocalMultiplayerIntroduction />} />
              <Route path="/fallguyslocalmultiplayer" element={<FallGuysAlternative />} />
              <Route path="/stumbleguyslocalmultiplayer" element={<StumbleGuysAlternative />} />
           
          **/}
                {articles.map(({ path, element }) => <Route path={path} element={element} />)}
                <Route path="/logs" element={<AdminLogsContainer />} />
                <Route path="/gameLogs" element={<GameLogsContainer />} />

              </Routes>
            </BrowserRouter>
          </LobbyContext.Provider>
        </SocketContext.Provider>
      </FeatureToggleContext.Provider>
    </FullScreen>

  );
}

export default App;
