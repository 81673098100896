import React from 'react';

import FallGuysAlternative from "../containers/fallguysalternative";
import IntroductionContainer from "../containers/introduction";
import Introduction2Container from "../containers/introduction2";
import Introduction3Container from "../containers/introduction3";
import Introduction4Container from "../containers/introduction4";
import Introduction5Container from "../containers/introduction5";
import Introduction6Container from "../containers/introduction6";
import Introduction7Container from "../containers/introduction7";
import Introduction8Container from "../containers/introduction8";
import IntroductionJackboxContainer from "../containers/introductionjackbox";
import IntroductionJackbox2Container from "../containers/introductionjackbox2";
import LocalMultiplayerIntroduction from "../containers/localmultiplayerintroduction";
import StumbleGuysAlternative from "../containers/stumbleguysalternative";
import FourPlayersIntroduction from "../containers/fourplayersintroduction";
import TwoPlayersIntroduction from "../containers/twoplayersintroduction";
import TwoPlayersPsIntroduction from "../containers/twoplayersps";
import IntroductionSpanishAlternative from "../containers/alternativeSpanish";

export const articles =  [
    {path: "/introduction",element : <IntroductionContainer />},
    { path:"/introduction" ,element:<IntroductionContainer />},
    { path:"/introduction2", element:<Introduction2Container  />},
    { path:"/introduction6", element:<Introduction6Container  />},
    { path:"/online-games-to-play-with-friends",element:<Introduction3Container  />},
     { path:"/play-games-with-friends",element:<Introduction4Container />},
    { path:"/free-pc-games-to-play-with-friends",element:<Introduction5Container />},
     { path:"/couch-co-op-gaming" ,element:<Introduction7Container/>},
     { path:"/airconsole-alternative",element:<Introduction8Container />},
     { path:"/jackbox-tv-alternative", element:<IntroductionJackboxContainer/>},
     { path:"/jackboxtv-alternative",element:<IntroductionJackbox2Container />},
     { path:"/localmultiplayergames",element:<LocalMultiplayerIntroduction/>},
     { path:"/fallguyslocalmultiplayer", element:<FallGuysAlternative/>},
     { path:"/fourplayergames", element:<FourPlayersIntroduction/>},
     { path:"/twoplayergames", element:<TwoPlayersIntroduction/>},
     { path:"/twoplayerps", element:<TwoPlayersPsIntroduction/>},
    { path:"/stumbleguyslocalmultiplayer", element:<StumbleGuysAlternative />},
    {path: '/alternativa-gratis-airconsole', element: <IntroductionSpanishAlternative />}
]

export const ArticleLinks = () => {
    return <>
        {articles.map(article=> <a  style={{width: 0, height: 0}} href={"https://www.play-cloud.com" + article.path} />)}
    </>
}