import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './game.module.scss'
import { Unity, useUnityContext } from "react-unity-webgl";
import { FeatureToggleContext, IPlayer, LobbyContext } from '../../App';
import useImagePreloader from '../../hooks/useImagePreloader';
import Logo from '../../components/logo';
import { useNoSleep } from '../../hooks/useNoSleep';
import GameTopLayer from '../../components/gameTopLayer';
import { useQuery } from '../../hooks/useQuery';
import dayjs from 'dayjs';
import { getCookie, reportEvent } from '../../services/analytics.service';
import { useNavigate } from 'react-router-dom';
import { getTranslation } from '../../utils/anyUtils';
import chromeImg from '../../assets/images/chrome.png'
import socketService from '../../services/socket.service';
import { postAnalytics } from '../../httpRequests/consoleRequests';
import { CDN_URL } from '../../consts/config';
import logoImg from '../../assets/images/logoImg.png';
import classNames from 'classnames';
import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();

function enableNoSleep() {
 noSleep.enable();
}

function GamesContainer({handle}: {handle: any}) {
  let timeStarting = useRef(new Date())
  const featureToggles = useContext(FeatureToggleContext);
  const [updateAppToLatest, setUpdateAppToLatest ] = useState(localStorage.getItem("updatedToLatest") == "true")
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false)
  const [isShowingNumberOfUsersError, setIsShowingNumberOfUsersError] = useState<boolean>(false)
  const navigate = useNavigate()
  const lobbyContext = useContext(LobbyContext);
  useNoSleep(true)
  const idleLobbyInterval = useRef<any>(null)
  const offerRefreshTimeout = useRef<NodeJS.Timeout | null>(null)
  const query = useQuery()
  const maxNOfPlayers = localStorage.getItem("maxNOfPlayers")

  const loadingImage = localStorage.getItem("loadingImage")
  // @ts-ignore
  const gameKey = query && query.gameKey ? query.gameKey : 'testgamelocal'
  const { imagesPreloaded } = useImagePreloader([loadingImage!]);
  const t = getTranslation("game")
  const [fakePercent, setFakePercent] = useState<number>(0)
  const nOfParticipants = lobbyContext.players.filter(p => p !== null && p.id !== null).length

  useEffect(() => {
    document.addEventListener('touchstart', enableNoSleep, false);

    offerRefreshTimeout.current = setTimeout( offerRefresh, 1000 * 90)
    const anyNav: any = navigator
    if ('wakeLock' in navigator) {
      anyNav["wakeLock"].request("screen").catch((e: any) => console.log("error locking " , e))
    }
        if(!lobbyContext.roomNumber) {
      window.location.href = window.location.protocol + "//" + window.location.host
      return;
    }
    setIsShowingNumberOfUsersError(nOfParticipants > parseInt(maxNOfPlayers as string))
    setTimeout(() => {
      setIsShowingNumberOfUsersError(false)
    }, 15000)
    postAnalytics(
      'host_started_downloading_game',
      {
        game_key: gameKey
      } );
    window.onpopstate = handleBackButton
    let fakePercentInterval : any
    fakePercentInterval  =  setInterval(increaseFakePercent, 500)
    return () => {
      document.removeEventListener('touchstart', enableNoSleep, false);

      clearInterval(fakePercentInterval)
    }
  }, [])
  
  const handleBackButton = () => {
    socketService.socket.emit("logToLobby", {message: "Host clicked back button"})
    window.location.href = window.location.protocol + "//" + window.location.host
  }
  const increaseFakePercent = () => {
    setFakePercent((fakePercent: number) => {
      if(fakePercent < 90) {
        return fakePercent + 1
      } else {
        return fakePercent
      }
    })
  }
  const showLocal = localStorage.getItem("localGame") == "true"
  const { unityProvider, isLoaded, loadingProgression, unload } = useUnityContext({
    loaderUrl:featureToggles.useCdn && !showLocal ?`${CDN_URL}/games/${gameKey}/Build/${gameKey}.loader.js` : `/${gameKey}/Build/${gameKey}.loader.js`,
    dataUrl: featureToggles.useCdn && !showLocal? `${CDN_URL}/games/${gameKey}/Build/${gameKey}.data` :`/${gameKey}/Build/${gameKey}.data`,
    frameworkUrl:featureToggles.useCdn && !showLocal ? `${CDN_URL}/games/${gameKey}/Build/${gameKey}.framework.js` :`/${gameKey}/Build/${gameKey}.framework.js`,
    codeUrl:featureToggles.useCdn && !showLocal? `${CDN_URL}/games/${gameKey}/Build/${gameKey}.wasm` :`/${gameKey}/Build/${gameKey}.wasm`,
  });
  useEffect(() => {

    setTimeout(showAd, 200)
    if(isLoaded) {
      
      if(offerRefreshTimeout.current) {
        clearTimeout(offerRefreshTimeout.current)
      }
      
      postAnalytics(
        'host_finished_downloading_game',
        {
          download_time_s: dayjs().diff(dayjs(timeStarting.current), "seconds"),
          game_key: gameKey
        } );
      socketService.socket.emit("logToLobby", {message: "The hosts Javascript has finished loading"})
    }
    return () => {
      window.removeEventListener('popstate',handleBackButton);
    }
  }, [isLoaded])
  let unloadRef = useRef<any>()
  unloadRef.current = unload
  const offerRefresh = () => {
    postAnalytics("host_offered_to_refresh_page", {game_key: gameKey})
    socketService.socket.emit("logToLobby", {message: "Host was offered to refresh page"})
    setShouldRefresh(true)
  }
  const loadingPercentage = Math.round(loadingProgression * 100);
  const showAd = () => {
    if(!!getCookie('cookieConsent')) {
        //@ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        //@ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  useEffect(() => {
    
    if(lobbyContext.players.filter(player => !!player).length === 0) {
      idleLobbyInterval.current = setTimeout(handleDisconnectGame, 1000 * 60 * 3)
    } else {
      if(idleLobbyInterval.current) {
        clearTimeout(idleLobbyInterval.current)
      }
    }


  }, [lobbyContext.players])
  useEffect(() => {
    localStorage.setItem("lastGamePlayed", gameKey)
    socketService.socket.on("gameDisconnected", handleGameDisconnected)
    reportEvent(
      'host_started_downloading_game',
      {
        game_key: gameKey
      } );
    return () => {
      if(offerRefreshTimeout.current) {
        clearTimeout(offerRefreshTimeout.current)
      }
      socketService.socket.off("gameDisconnected", handleGameDisconnected)
      try {
        if(unloadRef != null) {
           unloadRef.current()
        }
      } catch (e) {
  
      }
      if(idleLobbyInterval.current) {
        clearTimeout(idleLobbyInterval.current)
      }
    }
  }, [])
  const handleDisconnectGame = () => {
    socketService.emitSocketEvent("disconnectIdleGame", {roomNumber: lobbyContext.roomNumber})
  }
  const handleGameDisconnected = async () => {
    try {
      if(unloadRef != null) {
        await unloadRef.current()
      }
    } catch (e) {

    }
    navigate('/games')
  }

  const finalPercentage = loadingPercentage >= 90 ? loadingPercentage : fakePercent
 // const finalPercentage = loadingPercentage
  return (
    <div className={ classNames(classes.wrapper, {[classes.fullscreen]:handle.active}) }>
      <GameTopLayer gameMode handle={ handle }/>
      <div className={ classes.mainWrapper }> 
        {
              isShowingNumberOfUsersError &&
              <div className={classes.nOfPlayersWarning}>
                <div className={classes.warningTitle}>Warning</div>
                <div className={classes.warningDescription}>
                  This is can only be played with up to <strong className={classes.green}>{maxNOfPlayers}</strong> people.
                  You are <strong className={classes.red}>{nOfParticipants}</strong> therefore some people will not be able to play
                  </div>
                  <img src={ logoImg } className={ classes.logoImg } />

              </div>
        }
        {
           !imagesPreloaded &&
          <div className={ classes.logoWrapper }>
            <Logo/>
          </div>
        }
         {
              shouldRefresh &&
              <div className={ classes.notWorkingExplainer} >
                <div className={classes.loaderSlowExplainerTitle}>
                  {t.stuckTitle}
                  </div>
                  <div className={classes.loaderSlowExplainerFirst}>
                  {t.stuck}
                  </div>
                  <div className={classes.loaderSlowExplainerTitle2}>
                  {t.didntwork}
                  </div>
                  <div className={classes.loaderSlowExplainer}>
                    <strong>1.</strong>{ t.fixStep1}
                  </div>
                  <div className={classes.loaderSlowExplainer}>
                    <strong>2.</strong>{ t.fixStep2} <img src={chromeImg} className={classes.chromeImg} />
                  </div>
                  <div className={classes.loaderSlowExplainerLastly}>
                  { t.lastOption}
                   
                  </div>
                  <img src={ logoImg } className={ classes.logoImg } />

                </div>
            }
        {
          !isLoaded && <div className={ classes.loaderWrapper }>
            <img src={loadingImage!} className={ classes.loaderImg} />
              <div className={ classes.loaderWrapper }>
                <div className={ classes.loader }>
                  <div className={ classes.bar } style={{flex: finalPercentage}}/>
                  <div className={ classes.emptyPart } style={{flex: 100 - finalPercentage}}/>
                </div>
              </div>
              <div className={ classes.explainer }> 
                  {t.downloadingGame} {finalPercentage}%
              </div>
              <p className={ classes.tipWrapper }>
                  <b className={classes.tip}>{t.tip}:</b>{t.tipExplainer}

              </p>
          </div> 
        }
        {
          !isLoaded  &&
          <ins className={classNames("adsbygoogle",classes.testInter)}
              data-ad-client="ca-pub-1189369488359090"
            data-ad-slot="4509267557"
            data-ad-format="vertical"
            data-full-width-responsive="true"></ins>
        }
                {
          !isLoaded  &&
          <ins className={classNames("adsbygoogle",classes.testInter2)}
              data-ad-client="ca-pub-1189369488359090"
            data-ad-slot="3067496559"
            data-ad-format="vertical"
            data-full-width-responsive="true"></ins>
        }
      {/* !updateAppToLatest &&
      (<div className={ classes.modal }>
        <div className={classes.title}>
          {t.titleUpdateApps}
        </div>
        <div  className={classes.btn} onClick={() => {localStorage.setItem("updatedToLatest","true"); setUpdateAppToLatest(true)}}>
          {t.buttonUpdateApp}
        </div>
        <div  className={classes.btnExplain} >
          {t.explainUpdateApp}
        </div>
      </div>)*/}
        <Unity className={ classes.gameWrapper } unityProvider={unityProvider} />
      </div>


    </div>
  );
}

export default GamesContainer;
