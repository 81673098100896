export const partyGamesPath = '/playcloudpartygames'
export const partyGamesSimilarPath = '/partygamessimilartomarioparty'
export const partyGamesSimilarPath2 = '/playcloudpartygamessimilar'
export const partyGameSpasnishPath = '/playcloudpartyjuegoscomo'
export const iceHockeyPath = '/icehockeylocalmultiplayergame'
export const kartGamePath = '/kartAlternative'
export const scribleIoAlternativePath = '/scribleioalternative'
export const garticPhoneAlternativePath = '/garticphonealternative'
export const drawingGameForTvWithPhonesPath = '/drawinggamefortvwithPhones'
export const drawingGameForPartiesPath = '/drawinggameforparties'
export const drawingGameForFamiliesPath = '/drawinggameforfamilies'
export const drawAndGuessGamePath = '/drawandguessgame'
export const drawAndGuessGameSpanishPath = '/juego-de-dibujar-y-adivinar'
export const gameArticles  = [
    partyGamesPath,
    partyGamesSimilarPath,
    partyGameSpasnishPath,
    iceHockeyPath,
    kartGamePath,
    scribleIoAlternativePath,
    garticPhoneAlternativePath,
    drawingGameForTvWithPhonesPath,
    drawingGameForPartiesPath,
    drawingGameForFamiliesPath,
    drawAndGuessGamePath,
    drawAndGuessGameSpanishPath
]
export const GameArticleLinks = () => {
    return <>
        {gameArticles.map(article=> <a  style={{width: 0, height: 0}} href={"https://www.play-cloud.com" + article} />)}
    </>
}